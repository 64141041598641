.shipping{
    min-height: 100vh;
    background-color: hsl(149, 18%, 55%);
    position: relative;
}

.shipping_form{
    margin-top: 15px;
}

.shipping_submit{
    margin-top: 20px;
}

.shipping_form_desktop{
    width: 70%;
    margin:auto
}

.shipping_form_container{
    margin-top: 50px;
}

.shipping_header_desktop{
    font-size: 25px;
}
