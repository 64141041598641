.intro{
    height: calc(100vh - 70px);
    /* height: 100vh; */
    background-color: #F7B0AA;
    position: relative;
    /* top: 70px; */

}

.intro_desktop{
    height: calc(100vh - 70px);
    /* background-color: #F7B0AA; */
    position: relative;
    display: flex;
    flex-direction: row;
}

.headshot{
    height: 45%;
    padding-top: 20px;
    border-radius: 100%;
}

.headshot_desktop{
    height: 55%;
    padding-top: 50px;
    border-radius: 100%;
}

.bio{
    padding-top: 35px;
    padding-left: 15px;
    padding-right: 15px;
}

.bio_desktop{
    padding-top: 50px;
    padding-left: 150px;
    padding-right: 250px;
    text-align: center;
    font-size: 25px;
    line-height: 2;
}

.intro_desktop_left{
    background-color: #F7B0AA;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.intro_desktop_right{
    background-color: #F7B0AA;
    flex: 1; 
}