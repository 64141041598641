.order_display{
    text-align: left;
    margin: 15px;
    border-width: 5px;
    border-color: black;
    background-color: #7BC2BC ;
}

.order_display_desktop{
    text-align: left;
    margin: 15px;
    border-width: 5px;
    border-color: black;
    background-color: #7BC2BC ;
}

.manage_orders{
    background-color: teal;
    min-height: 100vh;
}

.manage_orders_header{
    font-size: 20px;
    font-weight: bolder;
    color:white
}

.delete_order{
    background-color: lightsalmon;
}

.edit_order{
    background-color: lightskyblue;
}

.order_important{
    font-size: 15px;
    font-weight: bolder;
}

#checkout_amt_dropdown_desktop{
    margin-top: 50px;
    width: 50%
}

.manage_orders_desktop_width{
    width: 50%;
    margin: auto;
}

.manage_order_back_button_wrapper_desktop{
    margin-top: 10px;
}

.manage_order_back_button_wrapper{
    margin-top: 5px;
}

.order_wrapper_desktop{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}