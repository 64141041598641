.blog{
    background-color: #F5CDB6;
    min-height: 100vh;
}
.blog_desktop{
    background-color: #F5CDB6;
    min-height: 100vh;
    margin-top: 50px;
}

.change_post_button{
    margin-top: 10%;
    background-color: #76A08A;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.blog_button_group{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2px;
    margin-bottom: 10px;
}

.blog_title{
    font-size: 25px;
}

.blog_body{
    font-size: 15px;
    margin-top: 10px;
    margin-right: 5%;
    margin-left: 5%;
}

.blog_title_desktop{
    font-size: 30px;
}

.blog_body_desktop{
    font-size: 20px;
    margin-top: 15px;
    margin-right: 10%;
    margin-left: 10%;
    line-height: 2;
}
/* .line{
    background-color: black;
    height: 2px;
    width: 100vw;
} */