.success{
    background-color: #F7B0AA;
    height: 100vh;
}

.success_text_desktop{
    font-size: 25px;
    padding-top: 2%;
    padding-left: 10%;
    padding-right: 10%;
}

.success_text_mobile{
    padding-top: 20%;
    padding-left: 10%;
    padding-right: 10%;
}

.success_bottom_half{
    padding-top: 20%;
}

.success_bottom_half_desktop{
    padding-top: 2%
}