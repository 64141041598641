.navbar {
    width: 100%;
    height: 70px;
    background-color: #F5CDB6;
    top: 0;
    z-index: 3;
}

.logo{
    font-size: 35px;
    font-weight: 200;
    text-decoration: none;
    color: black;
    margin-right: 40px;
} 

.logo_desktop{
    font-size: 35px;
    font-weight: 200;
    text-decoration: none;
    color: black;
    margin-right: 40px;
}

.left{
    display: flex;
    align-items: left;
}

.right{
    display: flex;
    align-items: right;
    /* flex-direction: row; */
    justify-content: space-evenly
}

.wrapper{
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    flex-direction: row;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 15px;
}

.wrapper_desktop{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px;

}

.options{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.options_desktop{
    display: flex;
    flex-direction: row;
    gap: 150px;
    justify-content: space-between;
}

.option{
    font-size: 14px;
    text-decoration: none;
    color: black;
}

.option_desktop{
    font-size: 18px;
    text-decoration: none;
    color: black;
}
