.stripe_button{
    margin-top: 10px;
}

.stripe{
    background-color: #F5CDB6;
    min-height: 100vh;
    position: relative;
}
.review_info{
    margin-top: 5%;
}

.review_info_desktop{
    display: flex;
    flex-direction: row;
    margin-top: 5%;
}

.review_module_desktop{
    flex: 1;
}

.stripe_text_desktop{
    font-size: 25px;
    line-height: 2;
}

.stripe_header_desktop{
    font-size: 25px;
    font-weight: bold;
}

.stripe_cover{
    height: 200px
}

.mobile_blank_page{
    height: 100vh;
    background-color: #F5CDB6;
}

.stripe_continue{
    background-color: #76A08A;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

