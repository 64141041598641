.latestBlog{
    height: 100vh;
    background-color: #F5CDB6;
}

.blog_frontpage{
    padding-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
}

.title_latest_blog{
    font-size: 20px;
    font-weight: 400;
}

.title_latest_blog_desktop{
    margin-top: 2%;
    font-weight: 400;
    font-size: 25px;
}

.content{
    padding-top: 20px;
}

.content_desktop{
    margin-top: 2%;
    font-weight: 300;
    font-size: 25px;
    line-height: 2;
    margin-left: 25%;
    margin-right: 25%;
}

.keepReading{
    margin-top: 60px;
}

.keepReading_desktop{
    margin-top: 20px;
}

.keepReadingButton{
    margin-top: 10%;
    background-color: #76A08A;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.keepReadingButton_desktop{
    font-size: 1.2rem;
    width: 200px;
    height: 50px;
    background-color: #76A08A;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}