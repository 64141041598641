.cart_header_desktop{
    font-size: 25px;
}
.cart{
    height: 100vh;
    background-color: #76A08A;
    position: relative;
}
.cart_wrapper{
    margin-top: 10px;
    display: flex;
    flex-direction: row;
}

.cart_wrapper_desktop{
    display: flex;
    flex-direction: row;
    margin-top: 5%;
}

.cover_cart{
    height: 300px;
}

.cart_right{
    display:flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    margin-left: 10px;
}

.cart_right_desktop{

    flex: 1;
}

.cart_left{
    margin-left: 15px;
}

.cart_left_desktop{
    flex: 1;
}

.checkout_amt_dropdown{
    height: 45px;
}

.checkout_amt_dropdown_desktop{
    width: 50%
}

.cart_bottom{
    padding-top: 100px;
}
.cart_continue{
    background-color: #F5CDB6 ;
    color: black;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.total_amt_desktop{
    font-size: 25px;
}

.total_num_desktop{
    font-size: 25px;
}

.button_cart_desktop {
    border: 0;
    line-height: 2.5;
    padding: 0 20px;
    font-size: 1.2rem;
    width: 200px;
    height: 50px;
    text-align: center;
    color: grey;
    text-shadow: 1px 1px 1px #000;
    border-radius: 10px;
    background-color: #F5CDB6 ;
    box-shadow: inset 2px 2px 3px rgba(255, 255, 255, .6),
                inset -2px -2px 3px rgba(0, 0, 0, .6);
   
}