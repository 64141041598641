.manage_blog{
    background-color: teal;
    min-height: 100vh;
}

.manage_blog_display{
    text-align: left;
    margin: 15px;
    border-width: 5px;
    border-color: black;
    background-color: #7BC2BC ;
    height: 115px;
}

.manage_blog_header{
    font-size: 20px;
    font-weight: bolder;
    color:white
}

.manage_blog_title{
    font-size: 15px;
    font-weight: bolder;
    text-align: center;
}
.delete_blog_button{
    background-color: lightsalmon;
}

.delete_display{
    margin-top: 50px;
    color: white;
    font-size: 50px;
}

.edit_blog{
    background-color: teal;
}

.edit_blog_button{
    background-color: lightskyblue;
}

.read_blog_button{
    background-color: wheat;
}

.read_display{
    text-align: left;
    margin: 15px;
    border-width: 5px;
    border-color: black;
    background-color: #7BC2BC ;
}

.blog_title_home{
    font-size: 15px;
    font-weight: bolder;
    text-align: center;
}

.manage_blog_desktop_margins{
    margin-left: 15%;
    margin-right: 15%;
}

.button_wrap_desktop{
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
}

#no_button{
   background-color: lightskyblue;
   color: white
}

#yes_button{
    background-color: lightsalmon;
    color: white
}

#manage_blog_textarea_desktop{
    font-size: x-large;
}

#manage_blog_input_desktop{
    font-size: x-large;
    text-align: center;
}

.manage_blog_back_button_wrapper_desktop{
    margin-top: 10px;
}

.manage_blog_back_button_wrapper{
    margin-top: 5px;
}
