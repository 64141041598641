.login{
    min-height: 100vh;
    background-color: teal;
}

.login_input{
    width: 100%; 
    padding: 12px; 
    border: 1px solid #ccc;
    border-radius: 4px; 
    box-sizing: border-box; 
    margin-top: 6px; 
    margin-bottom: 16px; 
    resize: vertical 
}

.login_input_desktop{
    width: 50%; 
    padding: 12px; 
    border: 1px solid #ccc;
    border-radius: 4px; 
    box-sizing: border-box; 
    margin-top: 6px; 
    margin-bottom: 16px; 
    resize: vertical 
}

.login_button_desktop_container{
    margin-top: 20px;
}

.login_button_desktop{
    height: 50px;
    width: 200px;
    background-color: #F7B0AA;
    font-size: 30px;
}

.login_button_mobile{
    height: 30px;
    width: 115px;
    background-color: #F7B0AA;
}
.admin_text_desktop{
    font-size: 30px;
}

.admin_header_desktop{
    font-size: 40px;
}

.admin_desktop_inputs{
    margin-top: 5%;
}