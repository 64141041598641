.billing{
    min-height: 100vh;
    background-color: #76A08A;
    position: relative;
}

.billing_form_desktop{
    width: 70%;
    margin:auto
}

.billing_form_container{
    margin-top: 50px;
}

.billing_header_desktop{
    font-size: 25px;
}
