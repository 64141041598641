.contact{
    /* height: calc(100vh - 70px); */
    min-height: 100vh;
    /* background-color: #9A872D; */
    background-color:#76A08A ;
    position: relative;
    /* top: 70px; */
}

.contact_desktop{
  display: flex;
  flex-direction: row;
  height: 100vh;
  /* background-color: #9A872D; */
  background-color:#76A08A ;
  position: relative;
  
}

input[type=text], select, textarea {
    width: 100%; 
    padding: 12px; 
    border: 1px solid #ccc;
    border-radius: 4px; 
    box-sizing: border-box; 
    margin-top: 6px; 
    margin-bottom: 16px; 
    resize: vertical 
  }
  
input[type=submit] {
    background-color: #F5CDB6 ;
    color: black;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  #contact_submit{
    margin-bottom:10px;
  }

  .submit_desktop{
    background-color: #F5CDB6 ;
    color: black;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1.2rem;
    width: 200px;
    height: 50px;
  }

.container {
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px;
  }

  .form{
      padding-top: 100px;
  }
   
  .contactMessage{
      padding-top: 50px;
  }

  .contact_message_desktop{
    padding-top: 20%;
    font-size: x-large;
  }

  .contact_left_desktop{
    flex: 1;
  }
  .contact_right_desktop{
    flex: 1;
  }