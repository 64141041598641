.dash_link{
    text-decoration: none;
    color: white;
}

.admin_dash{
    background-color: teal;
    min-height: 100vh;
}

.dash_link_container{
    height: 23vh;
    border-style: solid;
    border-width: 10px;
    border-color: white;
    vertical-align: middle;
}

.dash_link_container_wrapper{
    display: flex;
    flex-direction: column;
}

.admin_dash_header{
    font-size: 30px;
    color: white
}