.showcase{
    height: 100vh;
    background-color: #76A08A;
    position: relative;
}

.showcase_desktop{
    height: 100vh;
    background-color: #76A08A;
    position: relative;
    display: flex;
}

.cover{
    height: 50%;
    padding-top: 2px;
}

.cover_desktop{
    height: 80%;
    margin-top: 10px;
}
 

.button {
    border: 0;
    line-height: 2.5;
    padding: 0 20px;
    font-size: 1rem;
    text-align: center;
    color: grey;
    text-shadow: 1px 1px 1px #000;
    border-radius: 10px;
    background-color: #F5CDB6 ;
    box-shadow: inset 2px 2px 3px rgba(255, 255, 255, .6),
                inset -2px -2px 3px rgba(0, 0, 0, .6);
}

.button:hover {
    background-color: #F5CDB6;
}

.button:active {
    box-shadow: inset -2px -2px 3px rgba(255, 255, 255, .6),
                inset 2px 2px 3px rgba(0, 0, 0, .6);
}

.button_desktop {
    border: 0;
    line-height: 2.5;
    padding: 0 20px;
    font-size: 1.2rem;
    width: 200px;
    height: 50px;
    text-align: center;
    color: grey;
    text-shadow: 1px 1px 1px #000;
    border-radius: 10px;
    background-color: #F5CDB6 ;
    box-shadow: inset 2px 2px 3px rgba(255, 255, 255, .6),
                inset -2px -2px 3px rgba(0, 0, 0, .6);
   
}

.button_group_desktop{
    padding-top: 5%;
    display: flex;
    justify-content: center;
    gap: 20px;
}

.desc{

    padding-left: 15px;
    padding-right: 15px;
}

.desc_desktop{
    font-size: 25px;
    line-height: 2;
}

.showcase_desktop_left{
    padding-top: 10%;
    flex: 1;
}

.showcase_desktop_right{
    padding-top: 15%;
    padding-right: 5%;
    flex: 1;
}
