.post_blog_title{
    font-size: 20px;
    color: white
}

.post_blog_title_desktop{
    font-size: 30px;
    color: white
}

.post_blog{
    background-color: teal;
    min-height: 100vh;
}

.post_blog_button{
    background-color: white ;
}

.post_blog_button_desktop{
    height: 50px;
    width: 200px;
    background-color: #F7B0AA;
    font-size: 30px;
}

.post_blog_button{
    height: 30px;
    width: 115px;
    background-color: #F7B0AA;
}

#post_blog_textarea_desktop{
    font-size: x-large;
}

#post_blog_input_desktop{
    font-size: x-large;
    text-align: center;
}

.post_blog_button_wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px
}

.post_blog_button_wrapper_desktop{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px
}