html{
  scroll-behavior:smooth;
  scroll-snap-type: y mandatory;
}

/*For getting rid of the white space problem supposedly */
* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  /* background: transparent; */
}

.App {
  /* overflow-y: scroll; */
  text-align: center;
  margin: 0;
        
}

.app-container{

  height: 100vh;
  text-align: center;
  scroll-snap-type: y mandatory;
  scroll-padding: 10px;
  overflow-y: scroll;

}
.app-section{
  height: 100vh;
  scroll-snap-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}